import React from 'react';
import AuthService from '../../services/authentication/auth.service';

import { Navigate, useLocation } from 'react-router-dom';

interface AuthComponentProps {
  component: React.FC;
}

const AuthComponent: React.FC<AuthComponentProps> = ({ component: Component }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const ref = queryParams.get('ref');
  const redirect = ref ? '/login?ref=' + ref : '/login';

  return AuthService.isUserLoggedInLocal() ? <Component /> : <Navigate to={redirect} replace />;
};

export default AuthComponent;
